@tailwind base;
@tailwind components;
@tailwind utilities;

.maintenance, .support {
  background-color: #9774D9;
  color: #f1ebfc
}

.development.self {
  background-color: #38F4A5;
  color: #014729;
}
.creativity {
  background-color: #F7A653;
}

.work {
  background-color: #28BAFD;
}
.leisure {
  background-color: #F261C2;
}
.commuting {
  background-color: #FEEF2D;
}

.rest {
  background-color: #84939B;
  color: #ededed;
}